import { useState } from 'react'

function useLocalStorage(key, initialValue) {
  const [localStorageValue, setLocalStorageValue] = useState(() =>
    getLocalStorageValue(key, initialValue)
  )

  const setValue = (value) => {
    // Check if function
    const valueToStore =
      value instanceof Function ? value(localStorageValue) : value

    // Set to state
    setLocalStorageValue(valueToStore)

    // Set to local storage
    localStorage.setItem(key, JSON.stringify(valueToStore))
  }

  return [localStorageValue, setValue]
}

const getLocalStorageValue = (key, initialValue) => {
  const itemFromStorage = localStorage.getItem(key)
  if (itemFromStorage === 'undefined' || !itemFromStorage) {
    return initialValue
  } else {
    return JSON.parse(itemFromStorage)
  }
}

export default useLocalStorage
